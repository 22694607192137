import API from '../vue_shared/api' // eslint-disable-line import/no-useless-path-segments

export default {
  async fetchAbility({ commit }) {
    return API.fetchAbilityRequests().then((response) => {
      commit('setAbility', response.data.rules)

      if (response.data.preview_user) {
        commit('setPreviewUser', response.data.preview_user)
      }

      if (response.data.preview_user_id) {
        commit('setPreviewUserId', response.data.preview_user_id)
      }

      if (response.data.current_user) {
        commit('setCurrentUser', response.data.current_user)
      }
    })
  },

  async fetchAccount({ commit }) {
    return API.account().then((response) => {
      commit('setAccount', response.data)
    })
  },

  async signOut() {
    return API.signOut().then(() => {
      localStorage.clear()
      window.location.reload()
    })
  }
}
