import '../src/polyfills.js'
import axios from 'axios'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import VueCookies from 'vue3-cookies'
import AppHeader from '../src/vue_shared/components/app_header.vue'
import API from '../src/vue_shared/api'

document.addEventListener('DOMContentLoaded', () => {
  const csrfEl = document.getElementsByName('csrf-token')
  const token = csrfEl.length > 0 ? csrfEl[0].getAttribute('content') : ``
  axios.defaults.headers.common['X-CSRF-Token'] = token
  axios.defaults.headers.common.Accept = 'application/json'

  const headerEl = document.querySelector(
    '#app-header-legacy .mount'
  ) as HTMLElement

  if (!headerEl) {
    return
  }

  let permissions = {
    canManageInternalAdminTools: false,
    canReadAdminOnly: false,
    canReadStaffOnly: false,
    canManageAll: false,
    canPreviewUser: false,
    permissionsPreviewUser: ''
  }
  let user = null
  let account = null
  let requestPrettyId = null

  try {
    requestPrettyId = headerEl.dataset.requestprettyid
  } catch (error) {}
  try {
    permissions = JSON.parse(headerEl.dataset.permissions)
  } catch (error) {}
  try {
    user = JSON.parse(headerEl.dataset.user)
  } catch (error) {}
  try {
    account = JSON.parse(headerEl.dataset.account)
  } catch (error) {}

  const app = createApp(AppHeader, {
    canManageInternalAdminTools: permissions.canManageInternalAdminTools,
    canReadAdminOnly: permissions.canReadAdminOnly,
    canReadStaffOnly: permissions.canReadStaffOnly,
    canManageAll: permissions.canManageAll,
    canPreviewUser: permissions.canPreviewUser,
    permissionsPreviewUser: permissions.permissionsPreviewUser,
    requestPrettyId: requestPrettyId,
    user,
    account,
    onOnSignOut: () => {
      API.signOut().finally(() => {
        localStorage.clear()
        window.location.reload()
      })
    }
  })

  const router = createRouter({
    history: createWebHistory(),
    routes: []
  })
  app.use(router)
  app.use(VueCookies)
  app.mount(headerEl)
})
